import {isDraft} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {RegistrationButtonSkin as Presentation} from './registration-button-skin'
import {RuntimeProps, RsvpButtonStyleOwnProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): RuntimeProps => ({
  draftEvent: isDraft(state.event),
})

export const RegistrationButtonSkin = connect<RsvpButtonStyleOwnProps, RuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
