import React from 'react'
import classNames from 'classnames'
import {Subtitle} from '../../subtitle'
import c from '../../classnames.scss'
import {DH} from '../../../constants/data-hooks'
import s from './schedule.scss'
import {List} from './list'
import {Footer} from './footer'
import {ScheduleProps} from '.'

export const Schedule = ({
  hollowButton,
  t,
  mobile,
  items,
  total,
  navigateToSchedulePage,
  scheduleTitleText,
  roundedButton,
}: ScheduleProps) => (
  <div className={!mobile ? s.container : ''} data-hook={DH.SCHEDULE}>
    <Subtitle dataHook={DH.SCHEDULE_SUBTITLE} text={scheduleTitleText} />
    <List t={t} items={items} mobile={mobile} />
    <Footer
      total={total}
      t={t}
      mobile={mobile}
      hollowButton={hollowButton}
      roundedButton={roundedButton}
      navigateToSchedulePage={navigateToSchedulePage}
    />
    {mobile && <div className={classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor)} />}
  </div>
)
