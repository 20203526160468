import {hasImage, EventDetailsAlignment, HEADER_IMAGE_ALIGNMENT} from '@wix/wix-events-commons-statics'
import {getHeaderAlignment, getHeaderBorderWidth, getHeaderImageAlignment} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {isFullWidth, isResponsive} from '../../../../../commons/selectors/environment'
import {SideBySideLayout as SideBySideLayoutPresentation} from './side-by-side'

export interface SideBySideLayoutProps extends SideBySideLayoutRuntimeProps, SideBySideLayoutOwnProps {}

export interface SideBySideLayoutOwnProps {
  onClickRegistrationButton: Function
}

export interface SideBySideLayoutRuntimeProps {
  headerAlignment: EventDetailsAlignment
  imageVisible: boolean
  headerImageAlignment: HEADER_IMAGE_ALIGNMENT
  headerBorderWidth: number
  fullWidth: boolean
  responsive: boolean
}

const mapRuntime = ({state, host}: DetailsPageAppProps): SideBySideLayoutRuntimeProps => ({
  headerAlignment: getHeaderAlignment(state.component.settings),
  imageVisible: hasImage(state.event),
  headerImageAlignment: getHeaderImageAlignment(state.component.settings),
  headerBorderWidth: getHeaderBorderWidth(state.component.settings),
  fullWidth: isFullWidth(state, host.dimensions),
  responsive: isResponsive(host),
})

export const SideBySideLayout =
  connect<SideBySideLayoutOwnProps, SideBySideLayoutRuntimeProps>(mapRuntime)(SideBySideLayoutPresentation)
