import {withTranslation} from 'react-i18next'
import {getTickets, getTicketsPdf} from '../../../selectors/placed-order'
import {getFormButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {TicketsDownloadRuntimeProps} from './interfaces'
import {TicketsDownload as Presentation} from './tickets-download'

const mapRuntime = ({
  state,
  actions: {downloadTickets, getOrder},
  staticsBaseUrl,
}: DetailsPageAppProps): TicketsDownloadRuntimeProps => ({
  staticsBaseUrl,
  event: state.event,
  orderNumber: state.placedOrder.order.orderNumber,
  tickets: getTickets(state),
  buttonStyle: getFormButtonStyle(state.component.settings),
  ticketsPdf: getTicketsPdf(state),
  downloadTickets,
  getOrder,
})

export const TicketsDownload = connect<{}, TicketsDownloadRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
