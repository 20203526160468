import {getFormMessages} from '@wix/wix-events-commons-statics'
import {connect} from '../../runtime-context/connect'
import {DetailsPageAppProps} from '../../app/interfaces'
import {hasAgreedWithPolicies, hasPolicies} from '../../../selectors/policies'
import {getResponse} from '../../../selectors/rsvp'
import {RsvpFormContainer as Presentation} from './container'
import {RsvpFormContainerOwnProps, RsvpFormContainerRuntimeProps} from './interfaces'

const mapRuntime = ({
  state,
  actions: {submitRsvpStep, editRsvpStep, handleNextRsvpStep},
}: DetailsPageAppProps): RsvpFormContainerRuntimeProps => {
  const {currentStep, rsvpDetails} = state.rsvp
  return {
    currentStep,
    rsvpDetails,
    agreedWithPolicies: hasAgreedWithPolicies(state),
    hasPolicies: hasPolicies(state),
    response: getResponse(state),
    messages: getFormMessages(state.event),
    submitRsvpStep,
    editRsvpStep,
    handleNextRsvpStep,
  }
}

export const RsvpFormContainer =
  connect<RsvpFormContainerOwnProps, RsvpFormContainerRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
